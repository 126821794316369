:root { --primary: var(--ourprimary) !important; }
.logo-center { margin: 0 auto 15px; display: table; }
.MuiTypography-h6, .MuiTableCell-alignLeft { font-family: 'poppins', sans-serif !important; }
.login-main-page .login-wrapper { max-width: 540px; min-height: auto; width: 100%; padding: 50px; height: auto !important; box-shadow: 0 0 35px 0 rgb(154 161 171 / 15%); border-radius: 5px; }
.login-main-page:after { background: #F9F9F9; }
a.text-white.color-bg-link { text-decoration: underline; }
.btn-login-hover:hover { background: var(--oursecondary) !important; color: #fff !important; }
input.form-control.right-pad-otp { padding-right: 115px; }
.width-auto { width: auto !important; }
a.text-white.color-bg-link.width-auto.cancel-btn { float: left; background: transparent; text-decoration: none; padding: 0.938rem 1.5rem; font-weight: 700; }
.new-account.mt-5 { float: left; width: 100%; }
img.qr-code { width: 100%; max-width: 160px; margin: 0 auto; display: table; }
.fullWidth, .picker, .picker__trigger, .picker-input { float: left; width: 100%; }
.text-center.center-box, .center-box.download-link { margin: 0 auto; display: table; }
.center-box.download-link a { float: left; margin: 0 5px; }

.Security.text-white.center-security { margin: 26px auto 0; display: table; float: none; width: auto; }
.Security span { float: left; }
.Security h2 { float: left; margin-top: -6px; margin-left: 10px; font-weight: 600; } 
.logo { max-width: 271px; width: 100%;max-height: 80px; }
h4.login-text { font-size: 24px; }
.form-control { color: #7e7e7e; font-size: 16px; border-radius: 8px;  padding-left: 15px; height: 46px; }

.header-info.profile-info-box { background: #f1f1f1; float: left; width: 100%; margin-top: -15px; padding: 18px 24px; margin-bottom: 10px; }
.header-right .dropdown-menu { border-radius: 10px; overflow: hidden; }
p.verified-profile { float: left; width: 100%; margin-top: 9px; font-size: 13px; color: #2BC155; }
p.verified-profile i { font-size: 13px; margin-right: 4px; }

.graphsHeight { height: 250px; }
.deznav { background-color: var(--ourmenubackgroundcolor); }
.deznav .metismenu ul { padding: 0px; }
.deznav .metismenu { padding-right: 0px; }
.webkit-none { -webkit-appearance: none !important; appearance: none !important; appearance: auto; appearance: menulist-button; appearance: textfield; padding-right: 50px; }

.form-control:focus, .form-control:hover, .css-1pahdxg-control:hover, .css-1pahdxg-control:focus { border-color: var(--ourprimary) !important; opacity: 1; color: #000; }
.down-arrow { position: absolute; right: 19px; bottom: 15px; }
.css-yk16xz-control { border-radius: 8px; background: #fff; border: 1px solid #f0f1f5 !important; color: #B1B1B1; height: 46px; }
.css-1pahdxg-control, .picker-input__text { border: 1px solid #f0f1f5 !important; box-shadow: none !important; border-radius: 8px; background: #fff; border: 1px solid #f0f1f5 !important; color: #000; height: 46px; appearance: none !important; appearance: auto; appearance: menulist-button; appearance: textfield }
.webkit-none.css-2b097c-container div:focus { border: none !important;}

.css-1hb7zxy-IndicatorsContainer svg.css-6q0nyr-Svg { opacity: 0; }
.select-arrow { bottom: 25px; right: 30px; pointer-events: none; }
.card { border-radius: calc(0.75rem - 1px) calc(0.75rem - 1px) calc(0.75rem - 1px) calc(0.75rem - 1px) !important;}
.page-title-space { padding: 15px 25px; }

.css-tlfecz-indicatorContainer svg { display: none;}

@media only screen and (max-width: 1400px) {
    .form-control { height: 48px; }
    .btn { font-size: 1rem; }
    
}

@media only screen and (max-width: 768px) {
    .login-main-page .login-wrapper { padding: 50px 20px; }
    .page-title-space { padding: 15px 16px; }
    h2.text-black.font-w600.mb-0 { font-size: 20px; }
    a.back-to-page { margin-top: 0px; }
    .col-md-3.col-12.text-right.text-left-mobile { text-align: left !important; }
    .form-group .picker { margin-bottom: 15px; }
    .card-header.mobile-card-header { display: table; }
    .mobileFullWidth {  margin-top: 10px; }
    .mobileFullWidth .custom-dropdown.mb-0.dropdown { width: 100%; }
    .mobileFullWidth button.btn.btn-primary.btn-rounded.i-false.dropdown-toggle.btn { width: 100%; text-align: left; }
    .mobileFullWidth button.btn.btn-primary.btn-rounded.i-false.dropdown-toggle.btn i.fa.fa-caret-down.text-white.ml-3 { float: right; margin-top: 4px;}
}


@media only screen and (max-width: 600px) {
    .login-main-page .login-wrapper { padding: 0px 0px !important; }
    .center-box.download-link img.qr-code { max-width: 130px; }
    .Security span { float: left; width: 100%; margin-bottom: 10px; }
    .Security h2 { margin-left: 0px !important; }
    .table-responsive > .MuiPaper-root.MuiPaper-elevation2.MuiPaper-rounded > .MuiToolbar-root.MuiToolbar-regular.MuiToolbar-gutters { margin-left: 0px !important; }
    ul.bottom-links { margin: 0 auto 5px !important; display: table; float: none !important; }
    .footer .copyright { padding: 0px; }
    .profile-info .profile-details { display: block; float: left; width: 100%; }
    .profile .profile-photo { margin: 0px auto 20px; display: table; float: none; }
    .profile-personal-info.mt-4 h5 span.pull-right { display: none; }
}

@media only screen and (max-width: 500px) {
    .login-main-page .row.m-0.justify-content-center.h-100.align-items-center { background: #283593; }
    .vh-100.login-main-page .login-wrapper { box-shadow: none !important; }
    .form-control { font-size: 15px; }
 }

 @media (min-width: 900px) { 
    .modal { text-align: center; }
    .modal:before { content: ''; display: inline-block; height: 100%; vertical-align: middle; margin-right: -4px; }
    .modal-dialog { display: inline-block; text-align: left; vertical-align: middle; }
 }


 /* HTML CSS */
 .card-tabs-login.card-tabs .nav-tabs { background: transparent; }
.card-action.card-tabs-login .nav-tabs .nav-link { color: #fff; font-size: 1rem; }
.card-action.card-tabs-login .nav-tabs .nav-link.active { background: #fff; color: var(--ourprimary); }
a.text-white.color-bg-link { text-decoration: underline; }
.country-select { width: 100px !important; float: left; }
.form-control.country-value { width: calc(100% - 110px); float: right; }
.big-font { font-size: 25px; }
.position-relative { position: relative;}
a.resend-code-link { float: right; margin-top: 31px; position: absolute; right: 14px; top: 13px; font-size: 14px; color: var(--ourprimary); font-weight: 600; text-decoration: underline; }
.min-dropbox { min-width: 280px !important;}
.header-info.profile-info-box { background: #f1f1f1; float: left; width: 100%; margin-top: -15px; padding: 18px 24px; margin-bottom: 10px; }
p.verified-profile { float: left; width: 100%; margin-top: 9px; font-size: 13px; color: #2BC155; }
p.verified-profile i { font-size: 13px; margin-right: 4px; }
.payment-bx .accordion__item .accordion__body  { z-index: 2 !important; }
.fullWIdth { float: left; }
.link-icon { position: absolute; top: 10px; left: 20px; }
.card-header.invite-box .fullWIdth { padding-left: 50px; padding-right: 115px; }
p.copy-clipboard { position: absolute; right: 10px; font-size: 24px; font-weight: 600;  top: 0px; }
p.copy-clipboard a { color: var(--ourprimary); }
.pl-0 { padding-left: 0px !important; }
.pr-0 { padding-right: 0px !important;}
.widget-stat.widget-stat-custom .media > span { width: 55px; height: 55px; }
.float-right { float: right !important; }
.float-left { float: left !important; }
.mt--50 { margin-top: -50px;}
.recent-Transactions-bottom .dataTables_info { padding-left: 15px; margin-bottom: 15px; }
.fullWidth { float: left; width: 100%; }

p.instructions { font-size: 19px; color: #000; font-weight: 600; margin-bottom: 10px; }
p.instructions-details { font-size: 16px; margin: 0; }
p.instructions-details-btm.mt-2 { color: #000; font-weight: 500; }
.ml-3 { margin-left: 10px; }
.dropdown.bootstrap-select.default-select.form-control.wide.To-Currency { width: 120px; position: absolute; right: 20px; top: 38px; border: none; }
.dropdown.bootstrap-select.default-select.form-control.wide.To-Currency .btn { border: none !important; }
.highlight-text, .highlight-text:hover { color: #000; font-weight: 600;}

.table-responsive.hide-content table.dataTable.no-footer { border: none; }
.table-responsive.hide-content table.dataTable thead th, .table-responsive.hide-content table.dataTable thead td { border: none; }
.table-responsive.hide-content .dataTables_info, .table-responsive.hide-content .dataTables_paginate.paging_simple_numbers, .table-responsive.hide-content .dataTables_length, .table-responsive.hide-content .dataTables_filter { display: none; }
.table-responsive.hide-content { border: 1px solid #DDDDDD; }
.table-responsive.hide-content table.dataTable tbody th, .table-responsive.hide-content table.dataTable tbody td { padding: 8px 20px; }
.table-responsive.hide-content table.dataTable.no-footer { padding: 0px !important; }
.table-responsive.hide-content thead tr th { border-bottom: 1px solid #DDDDDD !important; }
table.dataTable thead th { color: var(--ourprimary) !important; }
.height-auto { height: auto !important; }
ol.number-list { margin: 0; padding: 0 0 0 15px; }
ol.number-list li { list-style: auto; padding-left: 11px; margin-bottom: 10px; }
.position-relative.address-copy { float: left; width: 100%; }
.position-relative.address-copy input { color: #666; font-size: 15px; font-weight: 600; background: #F9F9F9; }
.position-relative.address-copy i { font-size: 19px; margin-right: 5px; margin-top: 1px; }
img.img-fluid.qr-logo { max-width: 295px; margin: 0 auto; display: table; width: 100%; }
.table-responsive.hide-content-new table.dataTable.no-footer { border: none; }
.table-responsive.hide-content-new table.dataTable thead th, .table-responsive.hide-content-new table.dataTable thead td { border: none; }
.table-responsive.hide-content-new table.dataTable tbody th, .table-responsive.hide-content-new table.dataTable tbody td { padding: 8px 20px; }
.table-responsive.hide-content-new table.dataTable.no-footer { padding: 0px !important; margin-bottom: 25px; }
.table-responsive.hide-content-new thead tr th { border-bottom: 1px solid #DDDDDD !important; }
.table-responsive.hide-content-new thead .sorting_asc { padding-left: 18px !important; }
a.back-to-page { float: right; font-size: 16px; font-weight: 600; margin-top: 8px; color: var(--ourprimary); }
.font-bold { font-weight: bold; }
span.btc-price { position: absolute; right: 29px; top: 44px; pointer-events: none; }
label.form-label.bigfont { font-size: 24px; }
.ml-auto { margin-left: auto !important; }
.pr-0 { padding-right: 0px !important;}
ol.collapse-ul { padding-left: 15px; margin-top: 19px; }
ol.collapse-ul li { list-style: disc; }
.authentication-left { margin-left: 15px !important; margin-top: -4px; font-size: 16px !important; }
img.img-fluid.qr-small-logo { max-width: 185px; width: 100%; margin: 0 auto; float: left; }
.qr-info.qr-info-small { float: left; width: 100%; }
.Security { float: left; width: 100%; margin-top: 10px; }
.Security span { float: left; }
.Security h2 { float: left; margin-top: -6px; margin-left: 10px; font-weight: 600; }
ol.Guide { margin: 0; padding: 0 0 0 25px; }
ol.Guide li { list-style: unset; margin-bottom: 10px; }
.profile-photo { float: left; max-width: 140px; }
.details-info { font-size: 16px; font-weight: 400; margin-bottom: 10px !important; float: left; width: 100%; color: #464a53 !important; }
.details-info span { color: #898989; }
.col-12.btn-edit-primary { padding-left: 15px; margin-top: 15px; }
.ml-10{ margin-left: 10px !important;}

.news-scroll a { text-decoration: none; float: left; color: #fff; }
.news-scroll a:hover { color: #fff; }
.dot { height: 6px; width: 6px; margin-left: 15px; margin-right: 15px; margin-top: 0 !important; background-color: rgb(207,23,23); border-radius: 50%; float: left; }
.breaking-news.bg-primary { border-radius: calc(0.75rem - 1px) calc(0.75rem - 1px); margin-bottom: 40px; }
span.down { background: #FF2E2E; padding: 0 9px; border-radius: 5px; margin-left: 7px; }
span.up { background: #2BC155; padding: 0 9px; border-radius: 5px; margin-left: 7px; }

a.Transaction-history-check { margin: 0; color: var(--ourprimary); font-weight: 600; text-decoration: underline; }
.footer .copyright p { text-align: left; margin-bottom: 10px; }

ul.bottom-links { margin: 0 auto; padding: 0; display: table; }
ul.bottom-links li { float: left; margin: 0 5px; }
ul.bottom-links li a { font-size: 15px; color: var(--ourprimary) !important; opacity: 1; }
.text-right { text-align: right !important; }
a.btn.btn-primary.btn-rounded.mr-2 { margin-right: 20px !important;}

img.img-fluid.qr-small-logo.center-qr { margin: 0 auto; display: table; float: none; }
.qr-info.qr-info-small.mb-3.playstore-icon { margin: 0 auto; display: table; float: none; width: auto; }
.Security.text-white.center-security { margin: 26px auto 0; display: table; float: none; width: auto; }

h4.card-title.mb-4.heighlight-info { background: #f1f1f1; padding: 15px 15px; border-radius: 5px; padding-left: 20px; }

.btn-primary { background: var(--ourprimary) !important; border-color: var(--ourprimary) !important; }
[data-sidebar-style="full"][data-layout="vertical"] .deznav .metismenu > li.mm-active > a i {  background: var(--ourprimary) !important;}
.deznav .metismenu ul a:hover, .deznav .metismenu ul a:focus, .deznav .metismenu ul a.mm-active { color: var(--ourprimary); }

.btn-primary:hover  {  background: var(--ourprimaryhover) !important; border-color: var(--ourprimaryhover) !important; }
.hamburger .line, .bg-primary { background: var(--ourprimary) !important; }

.dropdown-menu .dropdown-item.active, .dropdown-menu .dropdown-item:active { color: #fff !important; background: var(--ourprimary) !important; }

.profile-tab .nav-item .nav-link:hover, .profile-tab .nav-item .nav-link.active { color: var(--ourprimary); border-bottom: 0.2px solid var(--ourprimary); }
.profile-info h4.text-primary, .profile-info .text-primary.h4 { color: var(--ourprimary) !important; }
.font-16 { font-size: 16px; }
.view-tr { background: #E3F9E9; padding: 14px; color: #2BC155; border-radius: 50%; }
img.iconsize { width: 35px; }

.primary-select + button.btn.dropdown-toggle.btn-light { background: var(--ourprimary) !important; color: #fff !important; border-radius: 0.625rem !important; }
.primary-select + button.btn.dropdown-toggle.btn-light .filter-option { color: #fff !important; font-weight: 400; font-size: 1rem; }

.default-select.style-1.primary-select .btn.dropdown-toggle:after { border-top-color: #fff !important;}
.hover-default:hover, .hover-default:focus { background: var(--oursecondary) !important; border-color: var(--oursecondary) !important; color: #fff !important; }
h2.text-black.font-w600.mb-0, .table-responsive.hide-content-new thead tr th { color: var(--ourprimary) !important;}
.bg-default-cstm { background: var(--oursecondary) !important; border-color: var(--oursecondary) !important; color: #fff !important; }

p.PaymentMethod { color: var(--ourprimary); font-weight: 600; font-size: 20px; }
.col-md-12.col-12.mb-1.sm-btn-cstm .btn { min-height:47px; width:calc(33% - 5px); margin: 0 2px; background: var(--ourprimary) !important; color: #fff; padding: 0.625rem 3px; font-size: 17px; }
.col-md-12.col-12.mb-1.sm-btn-cstm .btn:hover { background: #fff !important; color: var(--ourprimary); }

.f-18 { font-size: 18px;}

.content-body .dataTables_wrapper .dataTables_paginate .paginate_button:hover, .content-body .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover, .content-body .dataTables_wrapper .dataTables_paginate .paginate_button.current { background: var(--ourprimary) !important; }
.table-responsive.hide-content-new.table-hover.fs-14.card-table.col-12 .dataTables_wrapper .dataTables_paginate .paginate_button.current, .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover { color: #fff !important;}

.table-responsive.hide-content-new.table-hover.fs-14.card-table.col-12 a.paginate_button.current { color: #fff !important;}

.sk-three-bounce .sk-child { background: var(--ourprimary) !important;}

.content-body .dataTables_wrapper .dataTables_paginate .paginate_button.previous, .content-body .dataTables_wrapper .dataTables_paginate .paginate_button.next { color: #666 !important; }
.content-body .dataTables_wrapper .dataTables_paginate .paginate_button.previous:hover, .content-body .dataTables_wrapper .dataTables_paginate .paginate_button.next:hover { color: #000 !important; }

.content-body .card-body.payment-bx.p-0 .dataTables_wrapper .dataTables_paginate .paginate_button:hover, .content-body .card-body.payment-bx.p-0 .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover, .content-body .card-body.payment-bx.p-0 .dataTables_wrapper .dataTables_paginate .paginate_button.current { background: var(--ourprimary) !important; color: #fff !important;}

.deznav .metismenu ul a:hover, .deznav .metismenu ul a:focus, .deznav .metismenu ul a.mm-active, .deznav .metismenu > li:hover > a, .deznav .metismenu > li:focus > a { color: var(--ourprimary) !important; }


.dtp div.dtp-date, .dtp div.dtp-time { background: #284593 !important; }
.dtp > .dtp-content > .dtp-date-view > header.dtp-header { background: var(--ourprimary) !important; }
.dtp .dtp-buttons, .dtp table.dtp-picker-days tr td a.selected { background-color: var(--ourprimary) !important; }

.total-value { font-size: 25px; margin-top: 40px; margin-bottom: 0; color: var(--ourprimary); font-weight: 600; font-style: italic; }
button.exchange-max { background: #E9EAF4; color: var(--ourprimary); padding: 7px 13px; border: none; border-radius: 7px; font-weight: 700; }
.dropdown.bootstrap-select.default-select.form-control.wide.select-big button.btn.dropdown-toggle.btn-light { font-size: 21px; }
p.Deduction-text { font-size: 19px; margin-bottom: 10px; }
p.text-success.collect { font-size: 27px; }
.btn-close:focus { box-shadow: none !important; }

.share-info i { font-size: 25px; color: var(--ourprimary); cursor: pointer; z-index: 1; position: absolute; top: -17px; right: -10px; }
a:hover { color: var(--ourprimary); }
a.share-info.sharecstm { right: -50px; top: -29px; font-size: 25px; }
.accordion-header.rounded-lg { padding-right: 110px; }
.color-primary path { fill: var(--ourprimary) !important; }
span.me-3.bgl-primary.text-primary.inner-icon { padding: 0; background: transparent; }
.inner-icon img.iconsize.iconsize45 { width: 51px; border: 2px solid #D6F0FB; border-radius: 50%; padding: 5px; }
a.share-info.dropdown-toggle::after { display: none; }
.css-1pahdxg-control, .picker-input__text { color: #000000; }
tr.MuiTableRow-root.MuiTableRow-head th { width: auto !important; box-sizing: content-box !important; }
.highlight-text-coin { font-size: 24px; color: var(--ourprimary); }
.profile .profile-photo { margin-top: -10px; }
.nav-link { color: var(--ourprimary); }
p.resendcode { margin-top: 5px; margin-bottom: 0; font-size: 15px; text-align: right; }
p.resendcode a { color: var(--ourprimary); text-decoration: underline; }
.header-right .notification_dropdown .nav-link .badge { background: var(--ourprimary) !important;}
a.share-info.sharecstm.newone { right: 20px; top: 11px;  font-size: 25px; }
li.selected.active a.dropdown-item.active.selected { color: #fff !important; }

.to-hightlight { font-size: 21px; color: #000; font-weight: 600; float: left; width: 100%;}
.to-sm-hightlight { float: left; width: 100%; font-size:14px; }

a.btn.pad-cstm-two-btn { padding-left: 5px; padding-right: 5px; font-size: 16px !important; }
.float-right.Transfer-Transactions { min-width: 218px; }
.select2-container--default .select2-results__option--highlighted[aria-selected] { background-color: var(--ourprimary) !important; }
.information-icon { float: left; width: 100%; padding: 0 25px; margin-bottom: 15px; font-size: 15px; }
.information-icon span { margin-right: 15px; color: var(--ourprimary); }
.information-icon span i { font-size: 13px; margin-right: 3px; }
.Fees-title { font-size: 23px; font-weight: 600; color: var(--ourprimary); }
.form-control:disabled, .form-control[readonly] { background: #f5f5f5 !important; opacity: 1; }
.range-bg-gray { padding: 25px !important; background: #f5f5f5; margin-left: 15px; width: calc(100% - 30px); margin-bottom: 30px; border-radius: 9px; }
p.copy-clipboard.api-copy { margin-top: 43px; font-size: 16px; margin-right: 16px; }
.font-15 { font-size: 15px; }
span.dash-icon { font-size: 45px; color: var(--ourprimary); }
table.dataTable thead .sorting { background-position: center right 6px !important; padding-right: 28px; }
.yellow { color: #FFD43B; font-size: 15px; margin: 0 3px; }
.footer .copyright p { font-size: 15px; }
[data-sidebar-style="full"][data-layout="vertical"] .deznav .metismenu > li > a { font-size: 15px !important;}

.username-more { font-size: 20px; font-weight: 600; margin: 0; }

p.form-label.Generate-Link { color: #000; font-weight: 600; }
p.ShareVia { margin-bottom: 4px; color: #000; }
ul.share-link { margin: 0; padding: 0; float: left; width: 100%; }
ul.share-link li { float: left; }
ul.share-link li a { padding: 7px; background: var(--ourprimary); float: left; border-radius: 6px; text-align: center; margin-right: 5px; font-size: 18px; color: #fff; width: 47px; }

.Generate-Box input.form-control.input-default.text-black { max-width: calc(100% - 260px); float: left; margin-right: 12px; }
.Generate-Box { float: left; width: 100%; }

.dropdown.bootstrap-select.default-select.form-control.wide.account-box-drop {width: 100%; background: var(--ourprimary); min-width: 360px; }

.dropdown.bootstrap-select.default-select.form-control.wide.account-box-drop .filter-option-inner-inner { color: #fff; ;}
.dropdown.bootstrap-select.default-select.form-control.wide.account-box-drop>.dropdown-toggle:after { color: #fff ;}

h4.mb-0.text-success {
    color: green !important;
}
h4.mb-0.text-warning {
    color: orange !important;
}

.calendar__head--prev { left: 9px; } 
.calendar__head--next { right: 9px; }
.calendar__day { padding-top: 12px; }
.calendar__day--today { background: var(--ourprimary); color: #fff !important; border-radius: 5px; }
.calendar__day--start, .calendar__day--end, .calendar__day--selected { background: #e1e1e1 !important; color: #000 !important; border-radius: 5px; }
.calendar__head { background: #7053A2; }
.calendar__day--6 { color: #7e7e7e; }
.select-arrow1 { bottom: 15px; right: 30px; pointer-events: none; }
.dropdown-menu-right.right-cstm { float: right; left: auto !important; right: 0 !important; width: auto; }
.btn-rounded { border-radius: 0.625rem !important; }
.btn { padding: 0.625rem 0.938rem !important;}
.modal .modal-dialog { width: 100%; }
.calendar__body--table th { font-size: 13px !important; }
.calendar__body { padding: 5px; }


.tox.tox-tinymce { height: 250px !important; }
table th { font-weight: 600 !important; font-size: 18px !important; color: var(--ourprimary); }
table td { color: #000 !important; }
button.btn-xs.edit-shape { font-size: 14px; padding: 4px 10px !important; border-radius: 8px; margin: 0 3px; }

.accordion-primary .accordion__header.collapsed { background: #E9EAF4; border-color: #f0f1f5 !important; }
label.custom-control-label { font-size: 15px; text-indent: 8px; }

.eye-btn { position: absolute; right: 11px; cursor: pointer; top: 41px; color: #283593; font-size: 19px; }
.lable-text { font-size: 16px; color: #000; font-weight: 600; width: calc(100% - 82px); margin-bottom: 0px; }
.Setting-tones .custom-switch .custom-control-label::after { width: 1.25rem; background: #909090; height: 1.25rem; content: ""; position: absolute; border-radius: 1.5rem; box-shadow: 0 0 0.3125rem rgb(0 0 0 / 30%); -webkit-transition: all 0.2s; -ms-transition: all 0.2s; top: calc(0.15625rem + -4px) !important; transition: all 0.2s; }
.Setting-tones .custom-control-label::before { border: 0; cursor: pointer; margin-left: 1px; background: #d8d8d8; width: 36px !important; border-radius: 1.25rem !important; height: 0.875rem; position: relative; -webkit-transition: all 0.2s; -ms-transition: all 0.2s; transition: all 0.2s; background-image: none;}
.Setting-tones .custom-switch .custom-control-input:checked ~ .custom-control-label::after { left: calc(-2.25rem + 6px);}
.Setting-tones .coin-swithc-cstm.custom-control.custom-switch { float: right; width: 50px; margin-top: 1px; }
.css-2613qy-menu { position: relative; z-index: 111;}
.MuiTableHead-root { display: table-header-group; z-index: 1; position: relative; }
[data-header-position="fixed"] .nav-header { z-index: 1111;}
[data-header-position="fixed"] .header { z-index: 111; }
.z-111 { z-index: 11; }
.btn-xs.small-btn { font-weight: 600; padding: 0.438rem 1rem !important; font-size: 13px; }
.btn-light:hover { background: #e4e6f0 !important; border-color: #e4e6f0 !important;}

.MuiFormControl-root.MuiTextField-root.MTableToolbar-searchField-10 { max-width: 280px; width: 100%; }
.table-responsive .MuiInputBase-root { border: 1px solid #e2e2e2; padding: 0.3rem 0.5rem; color: #715d5d; border-radius: 5px; float: right; margin-right: 10px; }
.table-responsive .MuiInput-underline:before, .MuiInput-underline:hover:not(.Mui-disabled):before, .MuiInput-underline:after { border: none !important;}
.table-responsive .MuiInputBase-root:hover, .table-responsive .MuiInputBase-root:focus { border-color: #283593; }
.table-responsive .MuiInputAdornment-root.MuiInputAdornment-positionEnd button.MuiButtonBase-root.MuiIconButton-root { padding: 0px; background: transparent; }
.table-responsive .MuiToolbar-regular { padding-right: 0px; }
.table-responsive .MuiTableCell-head, .table-responsive .MuiTableSortLabel-root.MuiTableSortLabel-active, .MuiTableSortLabel-root:hover { color: var(--ourprimary); font-size: 18px; opacity: 1; }

.table-responsive .MuiInputBase-root.MuiTablePagination-input.MuiTablePagination-selectRoot { border: none !important; }
.table-responsive tfoot.MuiTableFooter-root tr td { border: none !important; margin-top: 15px; }
.table-responsive .MuiTableCell-root { padding: 8px 20px 8px 5px; }
.coin-img { max-width: 35px; }
.table-responsive h6.MuiTypography-root { font-size: 21px; color: #000; }
.table-responsive > .MuiPaper-root.MuiPaper-elevation2.MuiPaper-rounded > .MuiToolbar-root.MuiToolbar-regular.MuiToolbar-gutters { margin-left: -21px; margin-bottom: 20px; }
.bdr-btm-card { border-bottom: 1px solid #F0F1F5; }
.modal { z-index: 1111 !important;}
.modal-backdrop { z-index: 1111 !important; }
.btn-light:focus { background: #e4e6f0 !important;  border-color: #e4e6f0 !important; }


.text-input-box input{ width: 15% !important; margin: 0 2px; border-radius: 5px; height: 46px !important; font-size: 18px; font-weight: 600; }
.text-input-box .styles_react-code-input-container__tpiKG.undefined { width: auto !important; margin: 0 auto; }
select.form-control.webkit-none.select-account { background: #313794; color: #fff; font-size: 15px; padding-right: 35px; }
select.form-control.webkit-none.select-account + i { color: #fff; }
select.form-control.webkit-none.select-account option { background: #fff; color: #000; font-size: 16px; }
select.form-control.webkit-none.select-account option:hover { background: #313794; color: #fff; }

.table-responsive.file-upload-table { background: #f7f7f7; padding: 15px; border-radius: 5px; }
table.table.table-responsive-sm.mb-0.list-table-box th { border-bottom-width: 1px; font-size: 15px !important; font-weight: 600 !important; letter-spacing: 0.5px; border-color: #f0f1f5; color: #7e7e7e; }
table.table.table-responsive-sm.mb-0.list-table-box td { border-bottom-width: 1px; font-size: 15px !important; letter-spacing: 0.5px; border-color: #f0f1f5; color: #7e7e7e !important; }
.basic-form.custom_file_input { border: 1px solid #F0F1F5; border-radius: 0 15px 15px 0; overflow: hidden; }
.basic-form.custom_file_input .custom-file { height: 46px; margin-left: 9px; }  
.sm-btn-cstm-new-ss li a { color: #fff !important; margin-right: 7px; margin-bottom: 7px; background: #7053A2; border-radius: 0.625rem; padding: 0.75rem 1.25rem; float: left; }
.bdr-none { border: none; }

.news-scroll.marquee-container .overlay::before, .overlay::after { display: none; }
.marquee { padding: 15px 0; }

ul.nav.nav-tabs.crypto-tabs-btn { border: none; }
ul.nav.nav-tabs.crypto-tabs-btn li a { border-radius: 0.625rem; padding: 0.75rem 1.25rem; background: #7053A2; color: #fff; margin-right: 7px; margin-bottom: 15px; }
ul.nav.nav-tabs.crypto-tabs-btn li a.active { background: var(--ourprimary); }
.bitimg { width: 51px; border: 2px solid #D6F0FB; border-radius: 50% !important; padding: 5px; }
.coinname-new { font-size: 24px; margin-top: 10px; margin-left: 11px; }
.table-cstm-border { border: 1px solid #F0F1F5; margin-bottom: 25px; }
.table-cstm-border table { margin-bottom: 0px; min-width: 650px; overflow: auto; }
.table-cstm-border table th, .table-cstm-border .table tbody tr td { border-right: 1px solid #F0F1F5 }
.copyboard { float: left; font-size: 21px; position: absolute; right: 10px; top: 7px; cursor: pointer; color: var(--ourprimary); }
.copy-text { font-weight: 600; color: #000; background: #F5F5F5;}
.copy-text:hover, .copy-text:focus { background: #F5F5F5; border: 1px solid #f0f1f5 !important; padding-right: 45px; }

.form-control.form-control-lg { font-size: 21px !important; height: auto !important; }
.form-control.form-control-lg option { font-size: 16px !important;}

.countrybox { width: 110px; float: left; }
.country-text-box { float: right; width: calc(100% - 125px); }
.deznav .metismenu li.mm-active > a { color: #000; font-weight: 500; }
.col-xl-12.csmt-card .card-info { padding-top: 20px; padding-bottom: 20px; }
.fs-25 { font-size: 25px;}
.hidecardform .card-info.text-white, .hidecardform1 .card-info.text-white { padding: 13px; }
.select-btn { max-width: 200px; width: 100%; }
ul.benifits { margin: 0; padding: 0; position: relative; }
ul.benifits li { list-style: none; float: left; width: 100%; padding-left: 25px; margin-top: 11px; }
ul.benifits li i { position: absolute; left: 0; color: #2BC155; margin-top: 3px; font-size: 18px; }
.fees { background: #f1f1f1; float: left; width: 100%; padding: 15px; border-radius: 5px; margin-top: 15px; }
.address-select { float: left; width: 100%; max-width: 560px; border: 1px solid #f0f1f5 !important; border-radius: 10px; padding: 7px; margin: 5px 0; }   
.optionnew { float: left; width: 100%; }
.chkbox { float: left; width: 38px; margin: 15px 0 15px 12px; }
.AddressType { float: left; width: calc(100% - 182px); }
p.AddressTypeName { font-size: 18px; margin: 0; color: #000; font-weight: 600; }
p.AddressTypeDay { margin: 0; font-size: 13px; }
.priceBox { float: left; width: 125px; margin: 7px 0; }
.priceBox span { background: #e3e3e3; color: var(--ourprimary); padding: 10px 20px; border-radius: 25px; font-weight: 600; float: right; font-size: 15px; }
.delivery-option { font-size: 17px; color: #000; font-weight: 600; margin-top: 15px; margin-bottom: 7px; }
.optionnew input.form-check-input { width: 55px; }

.card-rounded { border-radius: 0.625rem; max-width: 50px; }
.hr-line { float: left; width: 100%; background: #DFDFDF; height: 1px; margin: 1rem 0; }

ul.position-relative.cstm-check { margin: 0px; padding: 0px; }
ul.position-relative.cstm-check li { padding-left: 50px; margin: 10px 0; }
ul.position-relative.cstm-check li i { position: absolute; margin-top: 4px; left: 24px; color: #2BC155; }
.setTabDesignbtn { float: left; width: 100%; }
ul.nav.nav-tabs.active-tabs a.nav-link.active { background: var(--ourprimary); color: #fff; }
.row.mb-4.bg-gray-new { background: #F4F5F9; padding: 2px 19px 5px 5px; border-radius: 7px; margin: 0 auto; }
.row.mb-4.bg-gray-new .form-check.custom-switch.toggle-switch.text-end.col-12.mt-3 { padding-left: 0; }
.row.mb-4.bg-gray-new .right-switch { float: right; margin-top: -6px; margin-right: -28px; padding-bottom: 7px; }
.row.mb-4.bg-gray-new .right-switch svg { display: none; }

.bg-gray-modal-switch { background: #fff; padding: 2px 19px 5px 5px; margin: 0 auto; border-bottom: 1px solid #DFDFDF; border-radius: 0; }
.bg-gray-modal-switch .form-check.custom-switch.toggle-switch.text-end.col-12.mt-3 { padding-left: 0; }
.bg-gray-modal-switch .right-switch { float: right; margin-top: -6px; margin-right: -28px; padding-bottom: 7px; }
.bg-gray-modal-switch .right-switch svg { display: none; }
.row.bg-gray-modal-switch label { font-size: 17px !important; margin-top: -6px; font-weight: 600; }
[data-sidebar-position="fixed"][data-layout="vertical"] .menu-toggle .deznav { z-index: 111;}
.No-Data { background: #f7f7f7; padding: 15px; border-radius: 5px; width: 100%; float: left; text-align: center; }
.custom-tab-1 .nav-link { color: #828690;}
.bg-success.passwordsendtext { text-align: center; padding: 15px; color: #fff; font-size: 15px; border-radius: 5px; margin-top: 17px; }
.bg-success.passwordsendtext p { margin: 0;}
.Notifications-box { float: left; width: 100%; }
.internal-title { font-size: 17px; color: #000; font-weight: 600; padding-left: 0px; margin-bottom: 10px; line-height: 20px; margin-top: 0px; }
.settings-toggle-btn { width: 100%; float: left; border-bottom: 1px solid #DEE2E6; padding: 3px 0 13px 0; }
.Notifications-box .settings-toggle-btn label { font-weight: 400 !important; color: #828690; }

.custom-control-input:checked ~ .custom-control-label::before { color: #fff; border-color: #D4D7E9; background-color: #D4D7E9; }
.custom-switch .custom-control-input:checked ~ .custom-control-label::after { background: #283593 !important;}

.single-page-font { color: var(--oursinglepagefontcolor) !important;}

.nav-header .logo-abbr { max-width: 180px;max-height: 70px;}
.show img.Mobile-Logo { display: none; }
.show.menu-toggle img.Mobile-Logo { display: block !important;}
.show.menu-toggle img.logo-abbr { display: none !important; }
.show.menu-toggle img.Mobile-Logo { max-width: 55px !important; }

@media (max-width: 1199px) {
    .nav-header .logo-abbr { display: none; }
    img.Mobile-Logo { display: block !important; }
 }
 
@media (max-width: 1023px) {
    .show img.logo-abbr { display: none !important ; }
    .show img.Mobile-Logo {  display: block !important; max-width: 60px; }
}


.check { font-size: 25px; opacity: 1; }
.check i.fa-check { border: 1px solid #1F1A50; border-radius: 50%; padding: 12px; color: #fff; background: #1F1A50; margin: 0 auto 15px; display: table; }
.check i.fa-times { width: 51px; text-align: center; border: 1px solid #7053A2; border-radius: 50%; padding: 12px; color: #fff; background: #7053A2; margin: 0 auto 15px; display: table; }
.check i.fa-times + p.fs-14.mb-1.Submitted { color: #7053A2 !important; }
p.fs-14.mb-1.Submitted { text-align: center; font-size: 21px !important; color: #1F1A50 !important; opacity: 1; font-weight: 600; margin-bottom: 20px !important; }
ul.btm-link { float: left; width: 100%; margin: 0; padding: 0; }
ul.btm-link li { float: left; width: calc(33% - 5px); text-align: center; margin: 0 3px; }
ul.btm-link li a { color: #fff; background: #303694; width: 100%; font-size: 13px; float: left; border-radius: 5px; padding: 8px 0 }

@media (max-width: 445px) {
    ul.btm-link li { width: 100%; margin-top: 5px; }
}

.cstm-autocomplete fieldset.PrivateNotchedOutline-root-5.MuiOutlinedInput-notchedOutline {
    border: none;
}
.cstm-autocomplete input#country-code {
    margin-top: -5px;
}

.hide-scrolllbar .MuiPaper-root { box-shadow: none; overflow: hidden; }

@media (max-width: 767px) {
    [data-sidebar-position="fixed"][data-layout="vertical"] .deznav { box-shadow: -12px 116px 16px 0px #000; }
    .country-text-box1, .countrybox1 { width: 100% !important; max-width: 100%; } 
}

.table-action-center th.MuiTableCell-head div { display: inline !important; }
.MuiTablePagination-selectLabel { display: none; }
.MuiTablePagination-selectLabel + .MuiInputBase-root.MuiInputBase-colorPrimary { border: none; }
input.form-control.text-black.disable-api-key { padding-right: 40px; }
.word-break { word-break: break-all !important; }
.modal .modal-dialog { margin: 0 auto;}
.max-height380 { max-height: 380px; }
.copyright p.float-left { width: 100%; text-align: center; }
.card-bx > img { object-fit: initial; }

.countrybox1 { width: 190px; float: left; }
.country-text-box1 { float: right; width: calc(100% - 200px); }

@media (max-width:575px) {
    .text-right.text-right-mobile { text-align: left !important; }
    span.float-right.fullmWidth { float: left; width: 100%; }
}

@media (min-width:767px) and (max-width:1297px) {
    .deznav .metismenu ul a { padding-left: 70px;}
}

.deznav .metismenu .has-arrow[aria-expanded=true]:after, .deznav .metismenu .mm-active > .has-arrow:after { transform: rotate(-222deg) translateY(-50%); }
.deznav .metismenu li.mm-active > .has-arrow:after { transform: rotate(-134deg) translateY(-50%); }

.nofoundFullWidth { width: 100% }
.nofoundTitle { border-bottom: 1px solid #E1E1E1; padding-bottom: 10px; margin-bottom: 20px !important; }
.nofoundText { font-size: 19px !important; width: 100%; float: left; text-align: left; margin-bottom: 26px; font-weight: 500; }
li.MuiButtonBase-root {
    width: 100% !important;
    text-align: left !important;
    padding: 8px !important;
    justify-content: left !important;
}